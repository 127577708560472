/* General and Reset */

:root {
  --main-bg-color: #fad5fc;
  --main-text-color: darkmagenta;
  --second-text-color: white;
  --second-bg-color: black;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 100%;
}

body {
  font-family: "Montserrat", sans-serif;
  color: var(--main-text-color);
  background-color: var(--main-bg-color);
  width: 100vw;

  overflow-x: hidden !important;
}


h2 {
  margin-top: 65px;
  margin-left: 1vw;
  font-size: 2rem;
  padding: 30px 10px 0 10px;
}



/* Responsive - Default functions for screens smaller than 950px width */
@media screen and (max-width: 950px) {

  /* General */
  h2 {
    font-size: 3rem;
  }

  
}


/* Responsive Medium Screens */
@media screen and (min-width: 500px) and (max-width: 900px) {

  /* General */
  html {
    font-size: 80%;
  }

}


/* Responsive Small Screens (mobile) */
@media screen and (max-width: 499px) {

  /* General */
  html {
    font-size: 70%;
  }
 
}


/* Responsive - Default functions for screens bigger than 2000px width */
@media screen and (min-width: 2000px) {

  /* General */
  html {
    font-size: 200%;
  }

  h2 {
    margin-top: 150px;
  }


}
