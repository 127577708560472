/* Menu da página */

.menuContent {
    display: flex;
    justify-content: space-around;
    align-items: center;

    position: fixed;
    top: 0;
    left: 0;
    min-width: 100vw;
    z-index: 999;
    height: 60px;

    background-color: rgba(139, 0, 139, 0.6);
    font-size: 0.9rem;

}


#logoWrite {
    font-weight: 700;
    letter-spacing: 2px;
    padding: 10px 20px;
    border: var(--second-text-color) solid 2px;
    border-radius: 10px;
    transition: 1.5s;
    color: var(--second-text-color);
    margin-left: 20px;
    cursor: pointer;
}

#logoWrite:hover {
    background-color: var(--main-bg-color);
    color: var(--main-text-color);
    border: var(--main-text-color) solid 2px;
}

.menuHamburguer {
    cursor: pointer;
}

.menuHamburguer div {
    width: 32px;
    height: 2px;
    background: var(--second-text-color);
    margin: 8px;
    display: none;
    transition: 0.2s;
}

.itensMenu {
    text-transform: uppercase;
    letter-spacing: 1px;
    color: var(--second-text-color);
    margin: 20px 20px;
    padding: 10px;
    font-weight: 700;
    position: relative;
    cursor: pointer;
}

.itensMenu.active {
    border-bottom: var(--second-text-color) solid 1px;
}

.itensMenu:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: var(--second-text-color);
    transition: all 2s ease;
}

.itensMenu:hover:before {
    width: 100%;
}


/* Functions to class active that will be put by JS for responsive */
.menuContent.active {
    transform: translateX(0%) !important;
}

.menuHamburguer.active #line1 {
    transform: rotate(-45deg) translate(-8px, 8px);
}

.menuHamburguer.active #line2 {
    opacity: 0;
}

.menuHamburguer.active #line3 {
    transform: rotate(45deg) translate(-5px, -7px);
}

/* Responsive - Default functions for screens smaller than 950px width*/
@media screen and (max-width: 950px) {

    .menuContent {
        display: flex;
        align-items: center;
    }

    .menuHamburguer div {
        display: block;
    }

    .navMenu {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        position: absolute;

        width: 50vw;
        height: 75vh;
        min-height: 300px;

        top: 60px;
        right: -100vw;
        background-color: rgba(139, 0, 139, 0.6);
        border-top: 2px solid rgba(255, 255, 255, 0.7);
        font-size: 1.3rem;
        transition: 0.6s;
    }

    .navMenu.active {
        right: 0;
    }

}

@keyframes navLinksAnimacao {
    from {
        opacity: 0;
        transform: translateX(50px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}


/* Responsive - Default functions for screens bigger than 2000px width */
@media screen and (min-width: 2000px) {

    .menuContent {
        height: 120px;
    }
}