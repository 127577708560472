#home {
    overflow: hidden;

    height: 100vh;
    width: 100vw;
    min-height: 600px;

    background-image: url("../../public/assets/img/pexels-element-digital-973401.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

}

#home h1 {
    display: none;
}

.sectionHome {
    margin-top: 18vh;

    height: 40vh;
    min-height: 300px;
    width: 60vw;
    min-width: 300px;

    margin-left: 12vw;

    overflow: hidden;
}

.homeTextTitle {
    margin: 20px;

    font-size: 1.8rem;
    font-weight: 700;
}

.textHome {
    width: 60vw;
    opacity: 0;

    margin: 15px 40px;
    padding: 10px 5px;

    font-size: 1.5rem;
    font-weight: 700;
}


/* Responsive Medium Screen */
@media screen and (max-width: 950px) and (min-width: 500px) {

    .sectionHome {    
        margin-left: 3vw;
    }
    
}

/* Responsive Small Screen (mobile) */
@media screen and (max-width: 499px) {

    #home {
        min-height: 450px;

    }

    .sectionHome {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        width: 80vw;
        height: 350px;
        margin: 15vh 5vw;

        background: rgba(226, 226, 226, 0.5);
        backdrop-filter: blur(2px);
        border-radius: 10%;
        box-shadow: 1rem 0.7rem 1rem rgba(27, 27, 27, 0.7);
    }

    .textHome {
        width: 70vw;
    }

}

/* Responsive Smaller Screen (like galaxy fold) */
@media screen and (max-width: 300px) {

    .sectionHome {
        margin: 15vh 5px;
        min-width: 270px;
    }
}