/* Footer */
#footerInfo {
    height: 4vh;
    width: 95vw;
    margin: 1vh 1vw;
    font-size: 0.9rem;
    text-align: center;
}

#footerInfo a {
    color: inherit;
}


/* Responsive - Default functions for screens smaller than 950px width */
@media screen and (max-width: 950px) {

    /* Footer */
    #footerInfo {
        height: 3vh;
    }

}