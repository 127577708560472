/* Faixas entre páginas */
.ribbons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    min-height: 25vh;
    width: 100vw;

    margin-top: 10px;
    background-color: #a4508b;
    background-image: linear-gradient(326deg, #a4508b 0%, #5f0a87 80%);

    color: var(--main-bg-color);
}

.ribbons .ribbonsTitles {
    height: 5vh;
    margin: 20px;
    padding: 3vh 0;
    font-size: 1.2rem;
    color: var(--second-text-color);
}

.ribbonsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 96vw;
    margin: 1vh 1vw;
}

.ribbons .brands {
    height: 12vh;
    margin: 10px 20px;
}

.ribbons .brands:hover {
    opacity: 0.4;
}

.ribbons .works {
    border-bottom: var(--second-text-color) solid 2px;
    border-radius: 25px;
    text-align: center;

    padding: 15px;
    margin: 10px;

    height: 8vh;
    width: 15vw;
    min-height: 50px;
    min-width: 155px;
    max-width: 250px;
    max-height: 80px;

    color: var(--second-text-color);
}

/* Responsive - Default functions for screens smaller than 1300 width*/
@media screen and (max-width: 1300px) {

    .ribbons .brands {
        margin: 10px 65px;
    }

    .ribbons .works {
        margin: 5px;
    }

}


/* Responsive - Default functions for screens smaller than 950px width*/
@media screen and (max-width: 950px) {
    .ribbons {
        flex-direction: column;
    }

    .ribbonsContainer {
        width: 95vw;
        flex-wrap: wrap;
    }

    .ribbons .ribbonsTitles {
        height: 8vh;
        margin: 10px;
        font-size: 1.6rem;
    }


    .ribbons .brands {
        margin: 10px 40px;
    }

    .ribbons .works {
        height: 5vh;
        margin: 10px;
    }

}

/* Responsive - Default functions for screens smaller than 500px width*/
@media screen and (max-width: 500px) {}



/* Responsive - Default functions for screens bigger than 2000px width */
@media screen and (min-width: 2000px) {


    .ribbons .brands {
        max-height: none;
    }


    .ribbons .works {

        height: 7vh;
        max-width: 350px;
    }

}