/* Seção Contato */
#contact {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1vh;

  height: 95vh;
  width: 97vw;
  min-height: 700px;

  overflow: hidden;
}

#contactContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 90vh;
  width: 93vw;
  min-height: 500px;

  margin: 5vh 3vw;

  border: var(--main-text-color) solid 10px;
  box-shadow: 0 0 0 10px var(--second-bg-color);
  color: var(--main-bg-color);
  background-color: var(--second-bg-color);
}

.contactLogos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 30vw;
  margin-left: 25px;
}

#logoRL {
  height: 25vh;
  margin: 20px;
  border-radius: 50%;
}


.contactLogos #logoWrite {
  border: var(--main-bg-color) solid 2px;
  color: var(--main-bg-color);

  width: 20vw;
  min-width: 225px;

  padding: 10px;
  margin: 0;

}

.contactLogos #logoWrite:hover {
  background-color: var(--main-bg-color);
  color: var(--main-text-color);
  border: var(--main-text-color) solid 2px;
}

#contactDates {
  margin-left: 1vw;
  padding: 5px;
  width: 30vw;
  font-size: 1.2rem;
}

#contactDates p {
  padding: 10px;
}

#contactDates a {
  color: inherit;
  text-decoration: none;
}

#addresContainer {
  width: 35vw;
  margin-right: 30px;
}

#addresContainer a {
  text-decoration: none;
  color: inherit;
}

#addresContainer p {
  padding: 5px;
}

.contactHighlights {
  color: var(--main-text-color);
  font-weight: 700;
  font-size: 1.2rem;
  margin-top: 30px;
  margin-bottom: 5px;
}

/* ICONES FONT AWESOME */
.fa-square-phone {
  padding: 5px 10px;
  font-size: 1.6rem;
  color: var(--main-bg-color);
  background-color: palevioletred;
  margin: 5px 10px;
  border-radius: 50%;
}

.fa-envelope {
  padding: 5px 7px;
  font-size: 1.6rem;
  color: var(--main-text-color);
  background-color: lightslategray;
  margin: 5px 10px;
  border-radius: 50%;
}

.fa-whatsapp {
  padding: 5px 10px;
  font-size: 1.6rem;
  color: white;
  background-color: #43D854;
  margin: 5px 10px;
  border-radius: 50%;
}

.fa-instagram-square {
  padding: 5px 10px;
  font-size: 1.6rem;
  color: var(--main-bg-color);
  background-color: var(--main-text-color);
  margin: 5px 10px;
  border-radius: 50%;

}

.fa-location-dot {
  padding: 10px;
  font-size: 1.2rem;
  color: var(--main-bg-color);
  background-color: var(--main-text-color);
  margin: 5px;
  border-radius: 50%;

}

/* Responsive for screens below 800px width */
@media screen and (max-width: 800px) {

  /* CONTATO */
  #contact {
    height: 100vh;
    min-height: 1100px;
    max-width: 98vw;
    margin-bottom: 3vh;
  }

  #contactContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;

    min-height: 900px;
    width: 92vw;
    padding: 5px;
  }

  .contactLogos {
    text-align: center;
    width: 80vw;
  }

  #contactDates {
    width: 80vw;
    padding: 5px;
  }

  #contactDates p {
    padding: 5px;
  }

  #phone {
    font-size: 1.4rem;
  }

  #addresContainer {
    width: 80vw;
    padding: 5px;
    margin-right: 0;
  }

  .contactHighlights {
    font-weight: 700;
    font-size: 1.2rem;
    margin: 30px 0 5px 0;
  }

  .fa-square-phone {
    font-size: 2rem;
  }

  .fa-envelope {
    font-size: 2rem;
  }

  .fa-whatsapp {
    font-size: 2rem;
  }

  .fa-location-dot {
    font-size: 1.6rem;
  }
}

/* Responsive for screens larger than 1000px width - only for logo */
@media screen and (min-height: 1000px) {

  #logoRL {
    height: 20vh;
  }
  
}
