/* Services */
#services {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  justify-content: space-between;
}

#servicesContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  width: 95vw;
  height: 90vh;

  margin-left: 2vw;

  font-size: 1.2rem;
  font-weight: 700;

}

.imgContainer {
  display: flex;
  justify-content: space-around;

  margin: 1vh 3vw;
}

.imgServices {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imgServices img {
  height: 52vh;
  max-height: 400px;
  border-radius: 20px;
  margin: 0 1vw;

  transition: 1s;
  max-width: 23vw;
}

.imgServices img:hover {
  transform: scale(1.2);
  opacity: 0.8;
}

.imgContainer .fa-angles-up {
  color: gray;
  margin-top: -30px;
}

.serviceName {
  margin-top: 20px;
}

.textServices {
  display: none;
  position: absolute;
  opacity: 0;
  padding: 5px;
  border-radius: 20px;
  background-color: rgba(230, 230, 230, 0.7);
  width: 20vw;
}

.imgServices:hover .textServices {
  display: block;
  animation: aparecerAbaixo 1.3s ease-out forwards;
}

.galeryLink {
  margin: 10px;

  font-size: 1rem;
  font-weight: 600;
  text-align: center;

  z-index: 99;

}


.buttonLink {
  font: inherit;
  cursor: pointer;
  margin-top: 10vh;
  border: var(--main-text-color) solid 1px;
  outline: 0;
  padding: 5px 15px;
  border-radius: 5%;

  background-color: #5f0a87;
  background-image: linear-gradient(326deg, #5f0a87 0%, #a4508b 80%);

  color: var(--main-bg-color);
  box-shadow: 0px 1px 2px 1px rgba(20, 20, 20, 0.6);

  text-decoration: none;
}

.buttonLink:hover {
  background-color: #a4508b;
  background-image: linear-gradient(326deg, #a4508b 0%, #5f0a87 80%);
}

.buttonLink:active {
  box-shadow: inset 1px 1px 4px 0px rgba(0, 0, 0, 0.8);
  opacity: 0.9;
}


/* Responsive - Default functions for screens bigger than 1200px width */
@media screen and (min-width: 1200px) {

  .imgServices img {
    max-height: none;
  }


  .imgContainer .fa-angles-up {
    margin-top: -40px;
  }

  .serviceName {
    margin-top: 30px;

  }

  .textServices {
    width: 17vw;
  }

}


/* Responsive - Default functions for screens bigger than 2400px width */
@media screen and (min-width: 2400px) {

  .imgContainer .fa-angles-up {
    color: gray;
    margin-top: -70px;
  }

  .serviceName {
    margin-top: 50px;

  }

}


/* Responsive - default functions to screens smaller than 1000px width */
@media screen and (max-width: 1000px) {

  #services {
    justify-content: center;
    min-height: 1000px;
  }

  #servicesContainer {
    justify-content: space-around;
    flex-wrap: wrap;
    font-size: 1.3rem;
    min-height: 850px;
    height: 95vh;
  }


  .imgContainer {
    justify-content: space-around;
    flex-wrap: wrap;
    min-height: 750px;
  }


  .imgServices {
    width: 40vw;
    height: 30vh;
    margin-top: 1vh;
    min-height: 280px;
  }

  .textServices {
    width: 130px;
    font-size: 1rem;
    margin-left: 10px;
  }

  .imgServices:hover .textServices {
    animation: aparecerAbaixoResp 1.3s ease-out forwards;
  }

  .imgServices img {
    /* min-height: 250px; */
    max-height: 300px;
    /* min-width: 180px; */
  }

  .galeryLink {
    margin-top: 1vh;
  }

}


/* Responsive - screens between 500px and 950px width */
@media screen and (min-width: 500px) and (max-width: 950px) {

  #servicesContainer {
    font-size: 1.3rem;
  }

  .imgServices img {
    min-height: 250px;
    min-width: 180px;
  }


}

/* Responsive - screens smaller 500px */
@media screen and (max-width: 499px) {

  #services {
    height: 95vh;
    width: 100vw;
    justify-content: center;
    min-height: 750px;
  }

  #servicesContainer {
    justify-content: center;
    font-size: 1.1rem;
    min-height: 600px;
    flex-wrap: nowrap;
    margin: 1vh 1vw;
  }

  .imgContainer {
    margin: 0 1vw;
    min-height: 500px;
  }

  .imgServices {
    max-height: 200px;
    min-height: 150px;
  }

  .imgServices img {
    max-height: 250px;
    max-width: 140px;
  }

  .textServices {
    max-width: 130px;
  }

  .galeryLink {
    margin-top: 20px;
    margin-bottom: 10px;
  }

}

/* Responsive - Default functions for screens bigger than 1100px height and smaller than 900px width */
@media screen and (min-height: 1100px) and (max-width: 900px) {
  
  .textServices {
    width: 200px;
  }

}

/* Responsive Smaller Screen (like galaxy fold) */
@media screen and (max-width: 300px) {

  .imgServices img {
    width: 35vw;
  }

}

/* Animações */
@keyframes aparecerAbaixo {
  from {
    opacity: 0;
    transform: translatey(60vh);
  }

  to {
    opacity: 1;
    transform: translatey(25vh);
  }
}


@keyframes aparecerAbaixoResp {
  from {
    opacity: 0;
    transform: translatey(25vh);
  }

  to {
    opacity: 1;
    transform: translatey(10vh);
  }
}