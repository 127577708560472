/* Section About */
#about {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  min-height: 850px;
}


#aboutContainer {
  display: flex;
  height: 95vh;
  min-height: 650px;
}

#aboutLeft {
  margin: 10vh 2vw;
  width: 42vw;
}

.aboutText {
  display: flex;
  flex-wrap: wrap;
  width: 45vw;
}

.aboutText .boxTextAbout {
  width: 20vw;
  padding: 10px 5px;
  margin: 5px;
  text-align: left;
  font-size: 0.9rem;
}

.highlightsText {
  font-weight: 700;
  font-size: 1.3rem;
}

#aboutRight {
  display: flex;
  justify-content: center;
  margin-top: 2vh;
  height: 75vh;
  width: 52vw;
  border-left: var(--second-text-color) solid 2px;
}

.aboutImages {
  margin: 3vh 1vw 1vh 2vw;
}

/* Responsive - Default functions for screens smaller than 1200px width*/
@media screen and (max-width: 1200px) {

  .aboutImages {
    margin-top: 15vh;
  }
  
}

/* Responsive - Default functions for screens smaller than 900px width*/
@media screen and (max-width: 900px) {
  
  #aboutContainer {
    margin-top: 1vh;
    flex-direction: column;
  }


  #aboutLeft {
    margin: 4vh 1vw;
  }

  .aboutText {
    width: 95vw;
    margin-left: 5vw;
  }

  #aboutRight {
    border-left: none;
    margin: 3vh 2vw;
    width: 92vw;
    border-top: var(--second-text-color) solid 2px;
  }

  .aboutImages {
    width: 70vw;
    
    height: 40vh;
    margin-top: 4vh;
  }

}

/* Responsive Medium Screens */
@media screen and (min-width: 400px) and (max-width: 950px) {

  .aboutText .boxTextAbout {
    width: 45vw;
    padding: 5px;
    margin: 5px;
    font-size: 1rem;
  }
  

}

/* Responsive Small Screens (mobile) */
@media screen and (max-width: 499px) {

  #about {
    height: 90vh;
    width: 100vw;
  }

  #aboutContainer {
    height: 80vh;
  }

  .aboutText .boxTextAbout {
    width: 42vw;
    padding: 5px;
    margin: 5px;
    font-size: 1rem;
  }
  
  .aboutImages {
    margin-top: 3vh;
  }

}


/* Responsive - Default functions for screens bigger than 1500px width */
@media screen and (min-width: 1500px) {

  
.highlightsText {
  font-weight: 700;
  font-size: 1.5rem;
}

.aboutText .boxTextAbout {
  font-size: 1.2rem;
}

}


/* Responsive - Default functions for screens bigger than 1100px height and smaller than 900px width - like Ipad Air */
@media screen and (min-height: 1100px) and (max-width: 900px) {
  
  .aboutImages {
    margin-top: 8vh;
  }

}